function splitDomTree(
  workingParent: Node,
  currentParent: Node,
  canaryDiv: HTMLElement,
  maxHeight: number,
): Array<Node> {
  let breakingNode: Node | null = null;
  let nodeIndex = 0;

  for (; nodeIndex < workingParent.childNodes.length; nodeIndex++) {
    const node = workingParent.childNodes[nodeIndex];

    // Ignorar nodos de comentarios y CDATA
    if (node.nodeType === Node.COMMENT_NODE || node.nodeType === Node.CDATA_SECTION_NODE) {
      continue;
    }

    if (node.nodeType === Node.TEXT_NODE) {
      const textNode = document.createTextNode('');
      currentParent.appendChild(textNode);

      const textContent = node.textContent ?? '';
      const textParts = textContent.split(/\b/);
      let currentText = '';

      let textPartIndex = 0;
      for (; textPartIndex < textParts.length; textPartIndex++) {
        const textPart = textParts[textPartIndex];
        const newText = currentText + textPart;
        textNode.textContent = newText;

        // Verificar si el contenido supera el maxHeight
        if (canaryDiv.scrollHeight > maxHeight) {
          textNode.textContent = currentText; // Revertir al texto anterior
          break;
        }

        currentText = newText;
      }

      // Si el texto se cortó porque excedió el maxHeight
      if (textPartIndex < textParts.length) {
        breakingNode = document.createTextNode(textContent.substring(currentText.length));
        break;
      }
    } else {
      const clonedNode = node.cloneNode(false);
      currentParent.appendChild(clonedNode);

      // Verificar si la altura máxima fue superada
      if (canaryDiv.scrollHeight > maxHeight) {
        const clonedWithChild = node.cloneNode(true);
        currentParent.removeChild(clonedNode); // Revertir la adición del nodo
        breakingNode = clonedWithChild;
        break;
      }

      // Recursivamente dividir el árbol DOM
      const splittedNodes = splitDomTree(node, clonedNode, canaryDiv, maxHeight);
      if (splittedNodes.length > 0) {
        breakingNode = node.cloneNode(false);
        for (const splitNode of splittedNodes) {
          breakingNode.appendChild(splitNode); // Añadir nodos divididos como hijos
        }
        break;
      }
    }
  }

  // Si se recorrieron todos los nodos o no hay un nodo de ruptura, devolver un array vacío
  if (nodeIndex >= workingParent.childNodes.length || !breakingNode) {
    return [];
  }

  // Devolver el nodo de ruptura y los nodos restantes
  const remainingNodes = Array.from(workingParent.childNodes).slice(nodeIndex + 1);
  return [breakingNode, ...remainingNodes];
}

export default splitDomTree;
